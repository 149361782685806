import { useState, useEffect } from 'react';
import { useData } from '../../hooks/useData';
import type { TableColumn } from '../../types/TableColumn';
import { sortDataByColumn } from '../../utils/tables';
import TableContainer from './TableContainer';
import TableHeader from './TableHeader';
import Table from './Table';
import TableHead from './TableHead';
import TableBody from './TableBody';
import StandardButton from '../buttons/StandardButton';
import ModalCreateFranchise from '../modals/ModalCreateFranchise';

interface FranchiseData {
  franchiseId: string;
  displayName: string;
}

export default function FranchiseTable(): JSX.Element {
  const { franchises } = useData();
  const [rows, setRows] = useState<FranchiseData[]>([]);
  const columns = [
    {
      label: 'Franchise ID',
      type: 'number',
      key: 'franchiseId',
    },
    {
      label: 'Display Name',
      type: 'string',
      key: 'displayName',
    },
  ];
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Display Name',
    type: 'string',
    key: 'displayName',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [isAddingFranchise, setIsAddingFranchise] = useState<boolean>(false);

  useEffect(() => {
    const newRows: FranchiseData[] = [];

    franchises.forEach((franchise) => {
      newRows.push({
        franchiseId: franchise.franchiseId,
        displayName: franchise.displayName,
      });
    });

    setRows(newRows);
  }, [franchises]);

  // Sort the data by the given column.
  function handleSort(
    rows: FranchiseData[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  return (
    <>
      <TableContainer>
        <TableHeader title={`Franchises (${rows.length})`}>
          <StandardButton
            onClick={() => {
              setIsAddingFranchise(true);
            }}
          >
            Add Franchise
          </StandardButton>
        </TableHeader>
        <Table>
          <TableHead
            sortColumn={sortColumn}
            columns={columns}
            handleSort={(col) => {
              handleSort(rows, col, sortDirection);
            }}
            sortDirection={sortDirection}
          />
          <TableBody columns={columns} data={rows} />
        </Table>
      </TableContainer>
      <ModalCreateFranchise
        isOpen={isAddingFranchise}
        setIsOpen={setIsAddingFranchise}
      />
    </>
  );
}
