/**
 * Restricts access to a route to only authenticated users.
 */
import { Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';

interface Props {
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<Props> = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <>{children}</> : <Navigate to="/signin" />;
};

export default PrivateRoute;
