import { useState, useEffect } from 'react';
import Table from './Table';
import TableBody from './TableBody';
import TableContainer from './TableContainer';
import TableHeader from './TableHeader';
import { useData } from '../../hooks/useData';
import type { TableColumn } from '../../types/TableColumn';
import TableHead from './TableHead';
import { sortDataByColumn } from '../../utils/tables';
import type { UserData } from '../../types/UserData';

export default function UsersTable(): JSX.Element {
  const { users } = useData();
  const [rows, setRows] = useState<UserData[]>([]);
  const columns: TableColumn[] = [
    { label: 'ID', type: 'string', key: 'userId' },
    { label: 'Email', type: 'string', key: 'email' },
  ];
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Email',
    type: 'string',
    key: 'email',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);

  useEffect(() => {
    setRows(users);
  }, [users]);

  // Sort the data by the given column.
  function handleSort(
    rows: UserData[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  return (
    <TableContainer>
      <TableHeader title={`Users (${rows.length})`}>
        <></>
      </TableHeader>
      <Table>
        <TableHead
          columns={columns}
          sortColumn={sortColumn}
          handleSort={(col) => {
            handleSort(rows, col, sortDirection);
          }}
          sortDirection={sortDirection}
        />
        <TableBody columns={columns} data={rows} />
      </Table>
    </TableContainer>
  );
}
