import { useEffect } from 'react';

import PageLayout from '../components/ui/PageLayout';
import { getFranchiseData, getUsers } from '../utils/api';
import { useData } from '../hooks/useData';
import UsersTable from '../components/tables/UserTable';
import LocationTable from '../components/tables/LocationTable';
import FranchiseTable from '../components/tables/FranchiseTable';

function Dashboard(): JSX.Element {
  const { setFranchises, setUsers } = useData();

  useEffect(() => {
    getFranchiseData()
      .then((franchises) => {
        setFranchises(franchises);
      })
      .catch((error) => {
        console.error('Error fetching franchise data:', error);
      });

    getUsers()
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, []);

  return (
    <PageLayout>
      <div data-cy="dashboard">
        {/* Dashboard actions */}
        <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
          {/* Left: Franchise and Location Selector */}
          <div className="flex flex-wrap justify-start gap-2 mb-4"></div>
          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2"></div>
        </div>
        {/* Tables */}
        <div className="flex flex-col gap-4">
          <UsersTable />
          <LocationTable />
          <FranchiseTable />
        </div>
      </div>
    </PageLayout>
  );
}

export default Dashboard;
