/**
 * Presentational component for the table body.
 */

import TableValue from './TableValue';
import type { TableColumn } from '../../types/TableColumn';

export default function TableBody({
  data,
  columns,
}: {
  data: any[];
  columns: TableColumn[];
}): JSX.Element {
  return (
    <tbody className="divide-y divide-gray-200 dark:divide-slate-700 text-xs bg-white dark:bg-[#182235] border-slate-200 dark:border-slate-700">
      {data.map((row, i) => (
        <tr key={i} data-cy={`row-${i}`}>
          {columns.map((col) => {
            const value = row[col.key as keyof typeof row];

            return (
              <TableValue
                key={col.key}
                value={value}
                column={col}
                onClick={row.onClick}
              />
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}
