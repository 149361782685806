import type { TableColumn } from '../types/TableColumn';

/**
 * Sorts the data by the given column and sort direction.
 * @param data The data to sort.
 * @param column The column to sort by.
 * @param sortDirection The sort direction, 1 or -1.
 * @returns The sorted data.
 */
export function sortDataByColumn(
  data: any[],
  column: TableColumn,
  sortDirection: number
): any[] {
  if (data.length === 0) {
    return data;
  }
  const newData = [...data];
  newData.sort((a, b) => {
    if (column.type === 'date') {
      const dateA = new Date(a[column.key] as string | number | Date);
      const dateB = new Date(b[column.key] as string | number | Date);
      return (dateA.getTime() - dateB.getTime()) * sortDirection;
    } else if (column.type === 'string') {
      return (
        (a[column.key].toLowerCase() < b[column.key].toLowerCase() ? -1 : 1) *
        sortDirection
      );
    }
    return (a[column.key] - b[column.key]) * sortDirection;
  });
  return newData;
}
