import { useEffect, useState } from 'react';
import ModalBasic from './ModalBasic';
import { updateFranchiseLocationId } from '../../utils/api';
import type { LocationData } from '../../types/LocationData';
import DropdownFull from '../dropdowns/DropdownFull';
import { useData } from '../../hooks/useData';
import { useToast } from '../../hooks/useToast';
import StandardButton from '../buttons/StandardButton';

export default function ModalEditLocation({
  location,
  setEditLocation,
}: {
  location: LocationData | null;
  setEditLocation: (value: LocationData | null) => void;
}): JSX.Element {
  const { franchises } = useData();
  const { setToast } = useToast();
  const [newFranchiseId, setNewFranchiseId] = useState<string>(
    location?.franchiseId ?? ''
  );

  useEffect(() => {
    setNewFranchiseId(location?.franchiseId ?? '');
  }, [location]);

  if (location === null) {
    return <></>;
  }

  function handleSave(): void {
    if (location === null) {
      return;
    }

    if (newFranchiseId === location.franchiseId) {
      setToast({
        show: true,
        message:
          'Error: New franchise ID is the same as the current franchise ID',
        type: 'error',
      });
      return;
    }

    updateFranchiseLocationId(
      location.franchiseId,
      location.locationId,
      newFranchiseId
    )
      .then(() => {
        setToast({
          show: true,
          message: 'Location updated successfully',
          type: 'success',
        });
        setEditLocation(null);
      })
      .catch((error) => {
        console.error('Error updating location:', error);
        setToast({
          show: true,
          message: 'Error updating location',
          type: 'error',
        });
      });
  }

  return (
    <ModalBasic
      title="Edit Location"
      isOpen={location !== null}
      setIsOpen={() => {
        setEditLocation(null);
      }}
    >
      <div className="p-4 gap-12 flex flex-col">
        <div>
          <b>Display Name:</b> {location.displayName}
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="franchiseId">
            <b>Franchise ID</b>
          </label>
          <DropdownFull
            options={franchises.map((franchise) => ({
              id: franchise.franchiseId,
              value: franchise.franchiseId,
            }))}
            selected={{
              id: newFranchiseId,
              value: newFranchiseId,
            }}
            handleChange={(id: string) => {
              setNewFranchiseId(id);
            }}
          />
        </div>
        <StandardButton onClick={handleSave}>Save</StandardButton>
      </div>
    </ModalBasic>
  );
}
