import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import ThemeProvider from './utils/ThemeContext';
import { ProvideAuth } from './hooks/useAuth';
import { ProvideToast } from './hooks/useToast';
import { ProvideLoad } from './hooks/useLoad';
import App from './App';
import AppLayout from './components/ui/AppLayout';
import { ProvideData } from './hooks/useData';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ProvideAuth>
      <ProvideToast>
        <ProvideLoad>
          <ProvideData>
            <AppLayout>
              <Router>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </Router>
            </AppLayout>
          </ProvideData>
        </ProvideLoad>
      </ProvideToast>
    </ProvideAuth>
  </React.StrictMode>
);
