/**
 * Presentational component for a table value.
 */

import type { TableColumn } from '../../types/TableColumn';
import EditButton from '../buttons/EditButton';

export default function TableValue({
  value,
  column,
  onClick,
}: {
  value: any;
  column: TableColumn;
  onClick?: () => void;
}): JSX.Element {
  if (column.key === 'edit' && onClick !== undefined) {
    return (
      <td className="p-1" data-cy={column.key}>
        <EditButton onClick={onClick} />
      </td>
    );
  }

  return (
    <td className="p-1" data-cy={column.key}>
      {value}
    </td>
  );
}
