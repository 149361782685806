/**
 * Edit Button
 */
import IconButton from './IconButton';
import EditIcon from '@mui/icons-material/Edit';

export default function EditButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  return <IconButton onClick={onClick} icon={<EditIcon />} />;
}
