import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { Amplify, Auth } from 'aws-amplify';
import awsExports from './aws-exports';
import './css/style.css';
import PrivateRoute from './PrivateRoute';

import Signin from './pages/auth/Signin';
import ChangePasswordPage from './pages/auth/ChangePassword';
import { useAuth } from './hooks/useAuth';

// Import pages
import Dashboard from './pages/Dashboard';

Amplify.configure(awsExports);
Auth.configure(awsExports);

function App(): JSX.Element {
  const auth = useAuth();
  console.log(auth);
  const location = useLocation();

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [location.pathname]); // triggered on route change

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route path="/signin" element={<Signin />} />
      <Route path="/change-password" element={<ChangePasswordPage />} />
    </Routes>
  );
}

export default App;
