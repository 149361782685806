import { useEffect, useState } from 'react';
import { useData } from '../../hooks/useData';
import type { TableColumn } from '../../types/TableColumn';
import Table from './Table';
import TableContainer from './TableContainer';
import TableHead from './TableHead';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { sortDataByColumn } from '../../utils/tables';
import type { LocationData } from '../../types/LocationData';
import ModalEditLocation from '../modals/ModalEditLocation';
import StandardButton from '../buttons/StandardButton';
import ModalCreateLocation from '../modals/ModalCreateLocation';

export default function LocationTable(): JSX.Element {
  const { franchises } = useData();
  const [rows, setRows] = useState<LocationData[]>([]);
  const [sortColumn, setSortColumn] = useState<TableColumn>({
    label: 'Location ID',
    type: 'number',
    key: 'locationId',
  });
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [editLocation, setEditLocation] = useState<LocationData | null>(null);
  const [creatingLocation, setCreatingLocation] = useState<boolean>(false);

  const columns: TableColumn[] = [
    {
      label: 'Location ID',
      type: 'number',
      key: 'locationId',
    },
    {
      label: 'Franchise ID',
      type: 'number',
      key: 'franchiseId',
    },
    {
      label: 'Display Name',
      type: 'string',
      key: 'displayName',
    },
    {
      label: '',
      type: 'edit',
      key: 'edit',
    },
  ];

  useEffect(() => {
    const newRows: LocationData[] = [];

    franchises.forEach((franchise) => {
      franchise.locations.forEach((location) => {
        newRows.push({
          locationId: location.locationId,
          franchiseId: franchise.franchiseId,
          displayName: location.displayName,
          onClick: () => {
            setEditLocation({
              locationId: location.locationId,
              franchiseId: franchise.franchiseId,
              displayName: location.displayName,
            });
          },
        });
      });
    });

    setRows(newRows);
  }, [franchises]);

  // Sort the data by the given column.
  function handleSort(
    rows: LocationData[],
    column: TableColumn,
    sortDirection: number
  ): void {
    const sortedData = sortDataByColumn(rows, column, sortDirection);
    setRows(sortedData);
    setSortColumn(column);
    setSortDirection(sortDirection * -1);
  }

  return (
    <>
      <TableContainer>
        <TableHeader title={`Locations (${rows.length})`}>
          <StandardButton
            onClick={() => {
              setCreatingLocation(true);
            }}
          >
            Add Location
          </StandardButton>
        </TableHeader>
        <Table>
          <TableHead
            columns={columns}
            sortColumn={sortColumn}
            handleSort={(col) => {
              handleSort(rows, col, sortDirection);
            }}
            sortDirection={sortDirection}
          />
          <TableBody columns={columns} data={rows} />
        </Table>
      </TableContainer>
      <ModalEditLocation
        location={editLocation}
        setEditLocation={setEditLocation}
      />
      <ModalCreateLocation
        creatingLocation={creatingLocation}
        setCreatingLocation={setCreatingLocation}
      />
    </>
  );
}
