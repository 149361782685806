import { getUserToken } from './user';
import type { FranchiseData } from '../types/FranchiseData';
import type { UserData } from '../types/UserData';

/**
 * Fetch franchise data from the API.
 * @returns {Promise<FranchiseData[]>}
 */
export async function getFranchiseData(): Promise<FranchiseData[]> {
  const token = await getUserToken();

  if (token === null) {
    return [];
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}/franchises`, {
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
}

/**
 * Create a new franchise.
 * @param displayName The display name of the franchise to create.
 * @returns {Promise<void>}
 */
export async function postFranchise(displayName: string): Promise<{
  displayName: string;
  franchiseId: string;
}> {
  const token = await getUserToken();

  if (token === null) {
    return {
      displayName: '',
      franchiseId: '',
    };
  }

  const res = await fetch(`${import.meta.env.VITE_API_URL}/franchises`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ displayName }),
  });

  return await res.json();
}

/**
 * Create a new user.
 * @param email The email address of the user to create.
 * @param locations The locations the user has access to.
 * @param franchises The franchises the user has access to.
 * @returns {Promise<void>}
 */
export async function postUser(
  email: string,
  locations: Array<{ locationId: string; franchiseId: string }>,
  franchises: Array<{ franchiseId: string; role: string }>
): Promise<void> {
  const token = await getUserToken();

  if (token === null) {
    return;
  }

  const res = await fetch(`${import.meta.env.VITE_API_URL}/users`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, locations, franchises }),
  });

  console.log(res);
}

/**
 * Add a location to a user.
 * @param userId The ID of the user to add the location to.
 * @param locationId The ID of the location to add.
 * @param franchiseId The ID of the franchise the location belongs to.
 * @returns {Promise<void>}
 */
export async function postUserLocation(
  userId: string,
  locationId: string,
  franchiseId: string
): Promise<void> {
  const token = await getUserToken();

  if (token === null) {
    return;
  }

  const res = await fetch(
    `${import.meta.env.VITE_API_URL}/users/${userId}/locations`,
    {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ locationId, franchiseId }),
    }
  );

  console.log(res);
}

/**
 * Add a franchise to a user.
 * @param userId The ID of the user to add the franchise to.
 * @param franchiseId The ID of the franchise to add.
 * @param role The role of the user in the franchise.
 * @returns {Promise<void>}
 */
export async function postUserFranchise(
  userId: string,
  franchiseId: string,
  role: string
): Promise<void> {
  const token = await getUserToken();

  if (token === null) {
    return;
  }

  await fetch(`${import.meta.env.VITE_API_URL}/users/${userId}/franchises`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ franchiseId, role }),
  });
}

/**
 * Update the franchise ID of a location.
 * @param oldFranchiseId The ID of the franchise the location currently belongs to.
 * @param locationID The ID of the location to update.
 * @param newFranchiseId The ID of the franchise to move the location to.
 * @returns {Promise<void>}
 */
export async function updateFranchiseLocationId(
  oldFranchiseId: string,
  locationID: string,
  newFranchiseId: string
): Promise<void> {
  const token = await getUserToken();

  if (token === null) {
    return;
  }

  await fetch(
    `${
      import.meta.env.VITE_API_URL
    }/franchises/${oldFranchiseId}/locations/${locationID}`,
    {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newFranchiseId }),
    }
  );
}

/**
 * Get a list of users.
 * @returns {Promise<UserData[]>}
 */
export async function getUsers(): Promise<UserData[]> {
  const token = await getUserToken();

  if (token === null) {
    return [];
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}/users`, {
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
}

export async function postLocation(
  franchiseId: string,
  locationId: string,
  displayName: string,
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  },
  timezone: string
): Promise<void> {
  const token = await getUserToken();

  if (token === null) {
    return;
  }

  await fetch(
    `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations`,
    {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        locationId,
        displayName,
        address,
        timezone,
      }),
    }
  );
}
