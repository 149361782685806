/**
 * Custom React hook for handling profile and location data at the component level.
 */

import { createContext, useContext, useState } from 'react';
import type { FranchiseData } from '../types/FranchiseData';
import type { UserData } from '../types/UserData';

// Interface for the data context.
interface DataContext {
  franchises: FranchiseData[];
  setFranchises: (franchises: FranchiseData[]) => void;
  users: UserData[];
  setUsers: (users: UserData[]) => void;
}

// Dummy context
const dummyContext: DataContext = {
  franchises: [],
  setFranchises: () => {},
  users: [],
  setUsers: () => {},
};

// Create the data context.
const dataContext = createContext(dummyContext);

// Create the data provider.
export const ProvideData: React.FC = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const data = useProvideData();
  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
};

// Create the useData hook.
export function useData(): DataContext {
  return useContext(dataContext);
}

// Create the useProvideData hook.
const useProvideData = (): DataContext => {
  const [franchises, setFranchises] = useState<FranchiseData[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);

  return {
    franchises,
    setFranchises,
    users,
    setUsers,
  };
};
