import { useState } from 'react';
import ModalBasic from './ModalBasic';
import { useData } from '../../hooks/useData';
import StandardButton from '../buttons/StandardButton';
import { postLocation } from '../../utils/api';

export default function ModalCreateLocation({
  creatingLocation,
  setCreatingLocation,
}: {
  creatingLocation: boolean;
  setCreatingLocation: (value: boolean) => void;
}): JSX.Element {
  const { franchises } = useData();
  const [data, setData] = useState({
    locationId: '',
    franchiseId: franchises.length > 0 ? franchises[0].franchiseId : '',
    displayName: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
    timezone: 'America/Chicago',
    customTimezone: ''
  });

  const [useCustomTimezone, setUseCustomTimezone] = useState(false);

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void {
    const { name, value } = event.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handleSave(): void {
    const timezone = useCustomTimezone ? data.customTimezone : data.timezone;

    postLocation(
      data.franchiseId,
      data.locationId,
      data.displayName,
      data.address,
      timezone
    ).catch((error) => {
      console.error('Error creating location:', error);
    });

    console.log('Save', { ...data, timezone });
  }

  return (
    <ModalBasic
      title="Create Location"
      isOpen={creatingLocation}
      setIsOpen={() => {
        setCreatingLocation(false);
      }}
    >
      <div className="p-4 gap-12 flex flex-col overflow-scroll h-96">
        <div className="flex flex-col gap-2">
          <label htmlFor="franchiseId">
            <b>Franchise ID</b>
          </label>
          <input
            type="text"
            name="franchiseId"
            value={data.franchiseId}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="locationId">
            <b>Location ID</b>
          </label>
          <input
            type="text"
            name="locationId"
            value={data.locationId}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="displayName">
            <b>Display Name</b>
          </label>
          <input
            type="text"
            name="displayName"
            value={data.displayName}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="line1">
            <b>Address Line 1</b>
          </label>
          <input
            type="text"
            name="line1"
            value={data.address.line1}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  line1: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="line2">
            <b>Address Line 2</b>
          </label>
          <input
            type="text"
            name="line2"
            value={data.address.line2}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  line2: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="city">
            <b>City</b>
          </label>
          <input
            type="text"
            name="city"
            value={data.address.city}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  city: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="state">
            <b>State</b>
          </label>
          <input
            type="text"
            name="state"
            value={data.address.state}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  state: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="zipCode">
            <b>Zip Code</b>
          </label>
          <input
            type="text"
            name="zipCode"
            value={data.address.zipCode}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  zipCode: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="country">
            <b>Country</b>
          </label>
          <input
            type="text"
            name="country"
            value={data.address.country}
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                address: {
                  ...prev.address,
                  country: event.target.value,
                },
              }));
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="useCustomTimezone">
            <b>Use Custom Timezone</b>
          </label>
          <input
            type="checkbox"
            name="useCustomTimezone"
            checked={useCustomTimezone}
            onChange={() => setUseCustomTimezone(!useCustomTimezone)}
          />
        </div>
        {useCustomTimezone ? (
          <div className="flex flex-col gap-2">
            <label htmlFor="customTimezone">
              <b>Custom Timezone</b>
            </label>
            <input
              type="text"
              name="customTimezone"
              value={data.timezone}
              onChange={handleChange}
              placeholder="Enter custom timezone"
            />
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <label htmlFor="timezone">
              <b>Timezone</b>
            </label>
            <select
              name="timezone"
              value={data.timezone}
              onChange={handleChange}
            >
              <option value="America/Chicago">America/Chicago</option>
              <option value="America/Los_Angeles">America/Los_Angeles</option>
              <option value="America/New_York">America/New_York</option>
              <option value="America/Mexico_City">America/Mexico_City</option>
              <option value="Europe/Madrid">Europe/Madrid</option>
              <option value="Europe/Lisbon">Europe/Lisbon</option>
            </select>
          </div>
        )}
        <StandardButton onClick={handleSave}>Save</StandardButton>
      </div>
    </ModalBasic>
  );
}
