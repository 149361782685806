import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import { useLoad } from '../../hooks/useLoad';
import AuthImage from '../../components/auth/AuthImage';
import AuthContainer from '../../components/auth/AuthContainer';

function Signin(): JSX.Element {
  const { signIn } = useAuth();
  const [data, setData] = useState({
    username: '',
    password: '',
  });
  // Toast for status messages
  const { setToast } = useToast();
  // Loading spinner
  const { setIsLoading } = useLoad();
  const navigate = useNavigate();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function handleSignin(): Promise<void> {
    if (data.username === '' || data.password === '') {
      setToast({
        show: true,
        message: 'Please fill in all fields',
        type: 'error',
      });
      return;
    }
    setIsLoading(true);

    try {
      const result = await signIn(data.username, data.password);
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setIsLoading(false);
        navigate(`/change-password?username=${data.username}`);
      } else {
        setIsLoading(false);
        navigate('/');
      }
    } catch (error: any) {
      console.log('error signing in', error);
      setIsLoading(false);
      setToast({
        show: true,
        message: error.message,
        type: 'error',
      });
    }
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      {/* Content */}
      <AuthContainer>
        <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">
          Welcome back! ✨
        </h1>
        {/* Form */}
        <div>
          <div className="space-y-4">
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="username"
              >
                Username
              </label>
              <input
                id="username"
                className="form-input w-full"
                type="username"
                name="username"
                onChange={handleChange}
              />
            </div>
            <div>
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="password"
              >
                Password
              </label>
              <input
                id="password"
                className="form-input w-full"
                type="password"
                name="password"
                autoComplete="on"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="mr-1"></div>
            <button
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
              onClick={() => {
                handleSignin().catch((error) => {
                  console.error('Error signing in', error);
                });
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </AuthContainer>
      {/* Image */}
      <AuthImage />
    </main>
  );
}

export default Signin;
