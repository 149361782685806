import { useState } from 'react';
import ModalBasic from './ModalBasic';
import { postFranchise } from '../../utils/api';
import StandardButton from '../buttons/StandardButton';
import { useData } from '../../hooks/useData';
import { useToast } from '../../hooks/useToast';

export default function ModalCreateFranchise({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}): JSX.Element {
  const { franchises, setFranchises } = useData();
  const { setToast } = useToast();

  const [data, setData] = useState({
    displayName: '',
  });

  function handlePost(): void {
    postFranchise(data.displayName)
      .then((response) => {
        setIsOpen(false);
        setToast({
          show: true,
          message: 'Franchise created successfully',
          type: 'success',
        });
        setFranchises([
          ...franchises,
          {
            displayName: data.displayName,
            franchiseId: response.franchiseId,
            users: [],
            locations: [],
          },
        ]);
      })
      .catch((error) => {
        console.error('Error creating franchise:', error);
        setToast({
          show: true,
          message: 'Error creating franchise',
          type: 'error',
        });
      });
  }

  return (
    <ModalBasic title="Create Franchise" isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="p-8">
        <div>
          <label htmlFor="displayName">Display Name</label>
        </div>
        <div>
          <input
            id="displayName"
            name="displayName"
            type="text"
            onChange={(event) => {
              setData((prev) => ({
                ...prev,
                displayName: event.target.value,
              }));
            }}
          />
        </div>
        <div className="py-4 w-full align-middle flex justify-center">
          <StandardButton onClick={handlePost}>Create Franchise</StandardButton>
        </div>
      </div>
    </ModalBasic>
  );
}
